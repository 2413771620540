/**
 * Constants Values used in applicaiton, ordered in Alpabatically
 */
export const AppConstants = {
    BUSINESSLOCATIONS: 'businesslocations',
    COSTCENTERS: 'costcenters',
    DAYSOFFSET: 'daysoffset',
    GETREQUEST: 'get',
    POSTREQUEST: 'post',
    ROUTES: 'routes',
    ADDRESSPOINT:'addresses',
    AIRRECIPIENT:'legacy-id-resolver/lookup',
    IDENTITY:'identity',
    Command:'command',
    DP:'dp',
    V1:'v1',
    V4:'v4',
    Address:'address'
}

