import { Component } from '@angular/core';
import { DataAccessService } from '../../services/data-access/data-access.service';
import { DataRepositoryService } from '../../services/data-repository/data-repository.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FavBloms } from '../../models/favourite';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../../entity/constants';
import { ConnectionService } from '../../services/connection/connection.service';
import { CommandType } from '../../entity/enums';
import { ErrorMessage, ErrorType } from '../../entity/error.const';
import { HttpStatusCode } from '@angular/common/http';
import { CreateRoute } from '../../entity/utility-interfaces';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-create-route',
  templateUrl: './create-route.component.html',
  styleUrls: ['./create-route.component.scss']
})
export class CreateRouteComponent {
  displayDialog: boolean = false;
  formPopupVisible: boolean = false;
  routeName: string;
  date: Date;
  businessLocationsList: FavBloms[] = [];
  tooltipName: string = 'Create Route';
  overnight: string = 'Overnight';
  routeForm: FormGroup;
  showOvernightIcon: boolean = false;
  minimumDate = new Date();
  createRouteData: CreateRoute[] = [];
  constructor(
    public dataAccessService: DataAccessService,
    private formBuilder: FormBuilder,
    private dataRepositoryService: DataRepositoryService,
    private messageService: MessageService,
    private connectionService: ConnectionService
  ) {
    this.routeForm = this.formBuilder.group({
      businessLocation: new FormControl('', [Validators.required]),
      routeName: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      begin: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
    });
  }
  /**
   * Method show popup
   */
  showRouteDialog() {
    this.formPopupVisible = true;
    this.routeForm.reset();
    this.getBusinessLocation();
  }
  /**
   * Clicking on save button it will call command api
   * It check validations and based on that show error messages
   */
  save() {
    if (this.routeForm.invalid) {
      this.routeForm.markAllAsTouched();
      this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.REQUIREDFIELDS });
      return;
    }
    const begin = this.routeForm.get('begin')?.value ?? null;
    const date = this.routeForm.get('date')?.value ?? null;
    if (begin && (begin.getTime() < this.minimumDate.getTime())) {
      if (date === this.minimumDate) {
        this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.PASTTIME });
        return;
      }
    }
    const timeWindow = this.createTimeWindow();
    this.createRouteData = [{
      id: Object.values(Guid.create())[0],
      business_location_id: this.routeForm.value.businessLocation.id,
      route_name: this.routeForm.value.routeName,
      route_state: "Sorting",
      source: "DP",
      command_type: CommandType.CreateRouteCommand,
      time_window: timeWindow,
      user_id: this.dataRepositoryService?.loggedInUSer?.username??''
    }]
    this.connectionService.httpReq(
      AppConstants.POSTREQUEST
      , AppConstants.Command
      , AppConstants.DP
      , this.createRouteData
    ).subscribe({
      next: (resp: any) => {
        this.dataAccessService.getRouteInfoDeltaOnce();
        this.formPopupVisible = false;
        this.messageService.add({ severity: ErrorType.SUCCESS, summary: '', detail: ErrorMessage.CREATEROUTESUCCESS });
      }, error: (err: any) => {
        if (err.status === HttpStatusCode.BadRequest) {
          this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.ROUTEEXIST });
        } else {
          this.messageService.add({ severity: ErrorType.ERROR, summary: '', detail: ErrorMessage.NETWORKERROR });
        }
      }
    })
    this.formPopupVisible = false;
  }

  getBussinessLocationData() {
    this.dataAccessService.getBLData().subscribe(res => {
      this.businessLocationsList = [...res];
    });
  }
  /**
   * Method use to get business location
   */
  getBusinessLocation() {
    if (this.dataRepositoryService.businessLocationListMap.size) {
      this.businessLocationsList = [...this.dataRepositoryService.businessLocationListMap.values()].map(data => {
        return {
          id: data.business_location_id,
          value: data.name
        }
      });
    } else {
      this.getBussinessLocationData();
    }
  }
  
  get formValue() {
    return this.routeForm.controls;
  }
  
  /**
   * Enable overnight icon when end time is less than begin time
   */
  onTimeChange() {
    const begin = this.routeForm.get('begin')?.value ?? null;
    const end = this.routeForm.get('end')?.value ?? null;
    if (begin && end) {
      this.showOvernightIcon = end.getTime() < begin.getTime();
    }
  }

  createTimeWindow() {
    const begin = this.routeForm.get('begin')?.value ?? null;
    const end = this.routeForm.get('end')?.value ?? null;
    const date = this.routeForm.get('date')?.value ?? null;
    return {
      begin: this.convertToEpoch(date,begin)??'',
      end: this.convertToEpoch(date,end)??''
    }
  }
  /**
   * common method to covert time into epoch
   */
  convertToEpoch(date: Date, time: any) {
    if (!date && !time) {
      return '';
    }
    return new Date(date).setHours(new Date(time).getHours(), new Date(time).getMinutes(), new Date(time).getSeconds()).toString()
  }
}
