<p-button (click)="showRouteDialog()" [pTooltip]="tooltipName" tooltipPosition="top"
  icon="pi pi-plus"></p-button>
<p-dialog header="Create Route" [(visible)]="formPopupVisible" [draggable]="false" [modal]="true" [closable]="true"
  [style]="{width: '50vw', height:'75vh', 'overflow':'hidden'}">
  <form class="form px-3" [formGroup]="routeForm">
    <div class="row py-2">
      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="businessLocation">Business Location</label>
        <p-dropdown [ngClass]="'blom-select'" [style]="{'width':'100%'}" optionLabel="value"
          [options]="businessLocationsList" formControlName="businessLocation" [placeholder]="'Select Business Location'"
          [filter]="true" filterBy="value"></p-dropdown>
        <span *ngIf="formValue['businessLocation'].invalid && formValue['businessLocation'].touched"
          class="error-validation">
          Business Location is required.</span>
      </div>
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="routeName">Route Name</label>
        <input id="routeName" class="form-control" type="text" formControlName="routeName">
        <span *ngIf="formValue['routeName'].invalid && formValue['routeName'].touched" class="error-validation">Route Name is
          required.</span>
      </div>
      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="routeState">Route State</label>
        <input id="routeState" class="form-control" type="text" value="Sorting" readonly>
      </div>
    </div>
    <p-divider align="left">
      <div class="inline-flex">
        <i class="pi pi-calendar mr-4"></i>
        <b> Time Window</b>
      </div>
    </p-divider>
    <div class="row py-2">
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="date">Date</label>
        <p-calendar [(ngModel)]="date" [style]="{'width':'100%'}" [readonlyInput]="true" [showIcon]="true" appendTo="body" inputId="date"
          formControlName="date" [minDate]="minimumDate"></p-calendar>
        <span *ngIf="formValue['date'].invalid && formValue['date'].touched" class="error-validation">Date is required.</span>
      </div>
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="begin">From</label>
        <p-calendar inputId="timeonly" [style]="{'width':'100%'}" [timeOnly]="true" formControlName="begin"
          (onSelect)="onTimeChange()"></p-calendar>
        <span *ngIf="formValue['begin'].invalid && formValue['begin'].touched" class="error-validation">From is required.</span>
      </div>
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="end">To</label>
        <div class="position-relative">
          <p-calendar inputId="timeonly" [style]="{'width':'100%'}" [timeOnly]="true" formControlName="end"
            (onSelect)="onTimeChange()"></p-calendar>
          <i *ngIf="showOvernightIcon" class="pi pi-moon overnight-icon" [pTooltip]="overnight" tooltipPosition="top"></i>
        </div>
        <span *ngIf="formValue['end'].invalid && formValue['end'].touched" class="error-validation">To is required.</span>
      </div>
    </div>
  </form>
  <p-footer>
    <button class="btn btn-primary me-1" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="formPopupVisible = false;">Cancel</button>
  </p-footer>
</p-dialog>