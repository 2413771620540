import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationResult, BrowserUtils, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { DataRepositoryService } from './shared/services/data-repository/data-repository.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Distribution_Planner';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private location: Location,
    private router: Router,
    private dataRepositoryService: DataRepositoryService
  ) { }

  ngOnInit() {
    const currentPath = this.location.path();
    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe = BrowserUtils.isInIframe() && !window.opener && currentPath.indexOf("logout") < 0; // Remove this line to use Angular Universal
    // this.setLoginDisplay();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        //can set user privileges here if any
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (sessionStorage.getItem('isAuth')) {
          sessionStorage.removeItem('isAuth');
        } else {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        }
      })

      this.getUserInfo();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (!this.loginDisplay) {
      this.router.navigate(['/auth']);
      sessionStorage.setItem('isAuth', 'false');
    }
  }

  /**
   * METHODS FOR USER ACCOUNT INFORMATION
   */
  getUserInfo() {
    let loggedInAccount: any = this.authService.instance.getAllAccounts()[0];
    this.dataRepositoryService.loggedInUSer = loggedInAccount;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
