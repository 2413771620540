import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, type: string): unknown {
    if(value) {
      // Extracting date components
      const convertToGMT = new Date(new Date(value).toLocaleString('sv-SE', {
        timeZone: 'Europe/Stockholm',
      }));
      const dateFormat = convertToGMT
      const month = String(dateFormat.getMonth() + 1).padStart(2, '0');
      const day = String(dateFormat.getDate()).padStart(2, '0');
      const year = dateFormat.getFullYear();
    
      // Extracting time components
      const hours = String(dateFormat.getHours()).padStart(2, '0');
      const minutes = String(dateFormat.getMinutes()).padStart(2, '0');
    
      // Format the date and time
      let formattedDateTime = '';
      switch (type) {
        case DateFormatType.DATE:
          formattedDateTime = `${month}/${day}/${year}`;
          break;
        case DateFormatType.TIME:
          formattedDateTime = ` ${hours}:${minutes}`;
          break;
        case DateFormatType.DATETIME:
          formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}`;
          break;
        default:
          break;
      }
      return formattedDateTime;

    }
    return null;
  }

}


export enum DateFormatType {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime'
}
