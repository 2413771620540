import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MsalGuard } from '@azure/msal-angular';

export function isInIframe() {
  return window !== window.parent && !window.opener;
}

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: '',
        loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
        canActivate:[MsalGuard]
      },
      {
        path:'**',
        redirectTo:'/auth',
        pathMatch:'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { useHash: true, onSameUrlNavigation: 'reload', initialNavigation: isInIframe() ? 'disabled' : undefined, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
